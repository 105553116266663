import { Outlet, useLoaderData } from '@remix-run/react';
import type { LoaderFunctionArgs } from '@remix-run/router';

import moment from 'moment';

import { Navigation } from '~/components/navigation';
import { AccountBanner } from '~/components/page/account-banner';
import { ChristmasClosureAlert } from '~/components/page/alert';
import { CutoffCountdown } from '~/components/page/cutoff-countdown';
import { Footer } from '~/components/page/footer';
import { getLoggedInUser } from '~/services/auth/functions';
import { getCart } from '~/services/cart.server';
import { getSignedInLinks } from '~/services/user.server';
import { getUser } from '~/services/user/queries';
import { getCutoffDate } from '~/utils/order/dates';
import { getSession } from '~/utils/sessions';
import { isShutdownPeriod } from '~/utils/shutdown';

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get('Cookie'));

  const now = moment().tz('Australia/Adelaide');
  const nextDeliveryDate = getCutoffDate(now).toISOString();

  const isChristmasShutdown = isShutdownPeriod();

  const userId = await getLoggedInUser(request, {
    shouldThrowWhenUnverified: false,
    shouldReturnUserWhenUnverified: false,
  });

  const user = userId ? await getUser({ id: userId }) : null;

  const roleSlugs = user?.userRoles.map((ur) => ur.role.slug) ?? [];
  const links = await getSignedInLinks(roleSlugs);

  return {
    cart: getCart(session),
    nextDeliveryDate,
    user:
      user != null
        ? { firstName: user.details.firstName, email: user.email }
        : null,
    isChristmasShutdown,
    links,
  };
}

export default function Layout() {
  const data = useLoaderData<typeof loader>();

  const itemsInCart = data.cart.items.length ?? 0;

  return (
    <div>
      <AccountBanner user={data.user} links={data.links} />

      <Navigation itemsInCart={itemsInCart} />

      {!data.isChristmasShutdown ? (
        <CutoffCountdown date={data.nextDeliveryDate} />
      ) : null}

      {data.isChristmasShutdown ? <ChristmasClosureAlert /> : null}

      <Outlet />
      <Footer />
    </div>
  );
}
